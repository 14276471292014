body {
}

/* Common styles Starts*/
body .each-sticky-note-outer {
    position: absolute;
}
.each-sticky-note-header {
    padding-bottom: 2px;
}
.each-sticky-note-header input {
    padding: 2px 2px;
    width: 100%;
}
.each-sticky-note-text-box textarea {
    border: none;
    width: 100%;
}
.each-sticky-note-delete {
    cursor: pointer;
    float: right;
    border: #000000 1px solid;
    font-weight: bold;
    padding: 0px 4px;
}
.each-sticky-note-options {
    float: right;
    position: relative;
}

.each-sticky-note-options-control {
    cursor: pointer;
    padding-right: 22px;
}
.hidden-item {
    display: none;
}

body .each-sticky-note-outer.ui-draggable-disabled, body .each-sticky-note-outer.ui-resizable-disabled,
body .each-sticky-note-outer.ui-state-disabled  {
    opacity: 1
}

/* Common styles Ends*/

/*Yellow theme Starts*/
.sticky-note-yellow-theme.each-sticky-note-outer {
    background-color: rgb(250, 250, 48);
    color: #000000;
    font-size: 12px;
    padding: 5px 5px;
    border: #987656 1px solid;
    border-radius: 5px;
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-header {
    border-bottom: solid 2px #ffffff;
    font-size: 120%;
    font-weight: bold;
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-header input {
    border:#ebe8e6 1px solid
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-text-box {
    overflow-x: hidden; overflow-y: auto; padding-top: 3px;
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-text-box textarea {
    background-color: rgb(250, 250, 48);
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-delete {
    border:#987656 1px solid
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-options {
    
}
.sticky-note-yellow-theme.each-sticky-note-outer .each-sticky-note-options-control {
    background: url("images/icon-settings-16.png") no-repeat;
    width: 16px;height: 16px;
}
/*Yellow theme Ends*/

/*Orange theme Starts*/
.sticky-note-orange-theme.each-sticky-note-outer {
    background-color: rgb(252, 190, 131);
    color: #000000;
    font-size: 12px;
    padding: 5px 5px;
    border: #987656 1px solid;
    border-radius: 5px;
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-header {
    border-bottom: solid 2px #ffffff;
    font-size: 120%;
    font-weight: bold;
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-header input {
    border:#ebe8e6 1px solid
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-text-box {
   overflow-x: hidden; overflow-y: auto; padding-top: 3px;
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-text-box textarea {
    background-color: rgb(252, 190, 131);
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-delete {
    border:#987656 1px solid
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-options {
    
}
.sticky-note-orange-theme.each-sticky-note-outer .each-sticky-note-options-control {
    background: url("images/icon-settings-16.png") no-repeat;
    width: 16px;height: 16px;
}
/*Orange theme Ends*/

/*Green theme Starts*/
.sticky-note-green-theme.each-sticky-note-outer {
    background-color: rgb(136, 246, 147);
    color: #000000;
    font-size: 12px;
    padding: 5px 5px;
    border: #987656 1px solid;
    border-radius: 5px;
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-header {
    border-bottom: solid 2px #ffffff;
    font-size: 120%;
    font-weight: bold;
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-header input {
    border:#ebe8e6 1px solid
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-text-box {
    overflow-x: hidden; overflow-y: auto; padding-top: 3px;
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-text-box textarea {
    background-color: rgb(136, 246, 147);
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-delete {
    border:#987656 1px solid
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-options {
    
}
.sticky-note-green-theme.each-sticky-note-outer .each-sticky-note-options-control {
    background: url("images/icon-settings-16.png") no-repeat;
    width: 16px;height: 16px;
}
/*Green theme Ends*/

/*Blue theme Starts*/
.sticky-note-blue-theme.each-sticky-note-outer {
    background-color: rgb(206, 231, 252);
    color: #000000;
    font-size: 12px;
    padding: 5px 5px;
    border: #987656 1px solid;
    border-radius: 5px;
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-header {
    border-bottom: solid 2px #ffffff;
    font-size: 120%;
    font-weight: bold;
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-header input {
    border:#ebe8e6 1px solid
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-text-box {
    overflow-x: hidden; overflow-y: auto; padding-top: 3px;
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-text-box textarea {
    background-color: rgb(206, 231, 252);
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-delete {
    border:#987656 1px solid
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-options {
    
}
.sticky-note-blue-theme.each-sticky-note-outer .each-sticky-note-options-control {
    background: url("images/icon-settings-16.png") no-repeat;
    width: 16px;height: 16px;
}
/*Blue theme Ends*/

/*Pink theme Starts*/
.sticky-note-pink-theme.each-sticky-note-outer {
    background-color: rgb(243, 172, 223);
    color: #000000;
    font-size: 12px;
    padding: 5px 5px;
    border: #987656 1px solid;
    border-radius: 5px;
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-header {
    border-bottom: solid 2px #ffffff;
    font-size: 120%;
    font-weight: bold;
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-header input {
    border:#ebe8e6 1px solid
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-text-box {
    padding-top: 3px;
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-text-box textarea {
    background-color: rgb(243, 172, 223);
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-delete {
    border:#987656 1px solid
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-options {
    
}
.sticky-note-pink-theme.each-sticky-note-outer .each-sticky-note-options-control {
    background: url("images/icon-settings-16.png") no-repeat;
    width: 16px;height: 16px;
}
/*Blue theme Ends*/

/*Like different themes mentioned above, more themes can be added!!!*/
