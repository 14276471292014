#divStickyNotesContainer {
    height: 450px;
    margin: 15px;/* 35px 5px 0px 5px*/
}

.modal-custom{
	width:100% !important;
    max-width: 100% !important;
    margin: 0;
}
.modal-custom .modal-content{
	 background-color: transparent; 
}
a#open_notes {
    float: center;
}
.each-sticky-note-themebox{
	left: -30px !important;
}
