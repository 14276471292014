.btn-save,
.swal-button--confirm {
    border: none !important;
    border-radius: unset !important;
    text-transform: uppercase;
    padding: 5px 25px !important;
    font-size: 13px !important;
    color: $white !important;
    background-color: $primary-dark !important;

    &:hover {
        color: $white !important;
    }
}

.btn-save-exit {
    border: none !important;
    border-radius: 0 !important;
    text-transform: uppercase;
    padding: 5px 25px !important;
    font-size: 13px !important;
    color: $white !important;
    background-color: $secondary-darker !important;

    &:hover {
        color: $white !important;
    }
}

.btn-cancel {
    border: none !important;
    border-radius: unset !important;
    text-transform: uppercase;
    padding: 5px 25px !important;
    font-size: 13px !important;
    color: $primary-dark !important;
    background-color: $secondary-lighter !important;
}

.btn-custom-light,
.swal-button--cancel {
    border: 1px solid $primary-dark !important;
    border-radius: unset !important;
    text-transform: uppercase;
    padding: 4px 24px !important;
    font-size: 13px !important;
    color: $primary-dark !important;
    background-color: $white !important;

    &:hover {
        color: $primary-dark !important;
    }
}

.custom-btn-group {
    .save {
        border: none !important;
        border-radius: 0 !important;
        text-transform: uppercase;
        padding: 5px 25px !important;
        background-color: $primary-dark !important;
        font-size: 13px !important;
        color: $white !important;

        &:hover,
        &:focus,
        &:active {
            z-index: 0 !important;
        }
    }

    .save-exit {
        border: none !important;
        border-radius: 0 !important;
        text-transform: uppercase;
        padding: 5px 25px !important;
        background-color: $secondary-darker !important;
        font-size: 13px !important;
        color: $white !important;

        &:hover,
        &:focus,
        &:active {
            z-index: 0 !important;
        }
    }

    .cancel {
        border: none !important;
        border-radius: 0 !important;
        text-transform: uppercase;
        padding: 5px 25px !important;
        color: $primary-dark !important;
        background-color: $secondary-lighter !important;
        font-size: 13px !important;
    }
}
