@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "Proxima Nova Regular";
    src: url("../fonts/ProximaNova-Regular.otf") format("truetype");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "Proxima Nova Bold";
    src: url("../fonts/Proxima-Nova-Bold.otf") format("truetype");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "Proxima Nova Extra Bold";
    src: url("../fonts/Proxima-Nova-Extrabold.otf") format("truetype");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "Proxima Nova Thin";
    src: url("../fonts/Proxima-Nova-Thin.otf") format("truetype");
}

@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: "Dreamwalks";
    src: url("../fonts/Dreamwalks.otf") format("truetype");
}
