/* login page styles */

.login-page {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;

    .main-div {
        display: flex;
        width: 100%;
        height: 100vh;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        align-items: center;

        .container {

            .alert {
                width: 350px;

                &.alert-success {
                    background-color: #D4EDDA !important;

                    strong {
                        color: #155724;
                    }
                }

                &.alert-danger {
                    background-color: #F8D7DA !important;

                    strong {
                        color: #721c24;
                    }
                }
            }
        }
    }

    .login-form {
        .container {
            max-width: 72%;
        }

        .password-div {
            position: relative;

            .toggle-password {
                position: absolute;
                right: 5%;
                top: 33%;
            }
        }

        .inline-div {
            display: flex;
            justify-content: space-between;
        }
    }
}

.forget-password {
    .save-exit {
        border-radius: 20px !important;
    }
}

.layer-two {    
    margin-left: auto !important;
    width: fit-content !important;
}
.layer-three {
    position : absolute !important;
    display: none !important;
    margin-left: auto !important;
    width: fit-content !important;
}
.layer-two a:hover + .layer-three {
    position : absolute !important;
    display : block !important;
}
.layer-three:hover {
    display: inline-block !important;
}

/* main dashboard page styles */

.dashboard-page {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;

    .main-div {
        height: 100%;

        .person-name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-side {
                display: flex;
                align-items: center;

                img {
                    width: 80px;
                    border-radius: 15px;
                }
            }

            .right-side {
                display: flex;
                align-items: center;

                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    background-color: $white;
                    object-fit: cover;
                }
            }
        }

        .modules-icons {
            padding: 45px;
            min-height: 85%;
            border-radius: 15px;
            background-size: 100% 100%;
            box-shadow: 0 -2px 5px 0 rgb(50 50 50 / 28%);

            .rounded-box {
                margin-bottom: 2rem;
                padding: 2rem 0;
                border-radius: 15px;
                background-color: $white;
                box-shadow: 0 5px 14px 0 rgb(58 58 58 / 20%);
                text-align: center;

                img {
                    width: 62px;
                    height: 62px;
                    object-fit: contain;
                }

                &:hover {
                    background-color: $primary-dark;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    h5 {
                        color: $white;
                    }
                }
            }
        }
    }
}

/* body styles */

.inner-page-body {
    height: 100vh !important;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;

    .wrapper {
        border-radius: 15px;
        background-color: $white;

        .content-wrapper {
            min-height: 0 !important;
            background-color: $white;
            margin: 0 !important;

            .content {
                padding: 0;
            }
        }
    }
}

/* sidebar styles */

aside.main-sidebar {
    width: 70px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-right: 1px solid $common-border-color;
    background-color: $white;
    overflow-y: visible;

    .menu {
        .sidebar-ul {
            .sidebar-li {
                padding: 3px 0;
                // border: 1px solid #e6e4f0;
                cursor: pointer;

                // &:hover {

                //     svg,
                //     .cls-1 {
                //         fill: white;
                //     }
                // }

                svg,
                .cls-1 {
                    fill: $primary-darker;
                }

                i {
                    color: hsl(216, 41%, calc(35% + 0%)) !important;
                }

                .sidebar-li-img {
                    width: 26px;
                    height: 26px;
                    object-fit: contain;
                }

                .sidebar-li-font {
                    width: 26px;
                    height: 26px;
                    object-fit: contain;
                    object-fit: contain!important;
                    width: 35px!important;
                    font-size: 1.7rem!important;
                }

                .bi-font-icon {
                    font-size: 1.7rem!important;
                }

                .bi{
                    &:before{
                        line-height: inherit!important;
                    }
                }
            }
            .back-menu{
                padding: 10px 0;
            }
        }

        .sidebar-ul {
            .sidebar-li {
                &.show {
                    margin-left: 5px!important;
                    border-top-left-radius: 12px!important;
                    border-bottom-left-radius: 12px!important;
                    background-color: hsl(216, 41%, calc(35% + 0%)) !important;

                    i {
                        color: white!important;
                    }
                }

                &:hover {
                    margin-left: 5px!important;
                    border-top-left-radius: 12px!important;
                    border-bottom-left-radius: 12px!important;
                    background-color: hsl(216, 41%, calc(35% + 0%)) !important;

                    i,
                    .bi-font-icon{
                        color: white!important;
                    }
                }
            }
        }        

        .logo {
            padding: 10px 0;

            img {
                width: 40px;
                height: 40px;
                border-radius: 8px;
            }
        }

        .logout {
            position: absolute;
            bottom: 0;
            padding: 0;
            width: 100%;
            border-bottom-left-radius: 15px;
            background: $primary-dark;

            img {
                width: 20px;
                height: 20px;
                object-fit: contain;
            }

            i {
                width: 20px!important;
                height: 20px!important;
                object-fit: contain!important;
                color: white!important;
                font-size: 1.2rem!important;
            }
            .bi{
                &:before{
                    line-height: inherit!important;
                }
            }
        }
    }
}

/* header styles */

header {
    margin-left: 70px;
    border-bottom: 1px solid $common-border-color;

    .main-div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-side {
            display: flex;
            align-items: center;

            .client-dashbaord-text {
                display: flex;
                align-items: center;

                .dropdown {
                    .btn.dropdown-toggle {
                        font-size: 18px;
                        background-color: unset;
                        border: none;
                        color: $primary-dark;
                        padding: 0;
                        vertical-align: top;
                        margin-top: -1px;
                        margin-left: 1rem;

                        .fa-sort-down {
                            vertical-align: text-top;
                        }
                    }

                    .dropdown-menu {
                        border: none;
                        margin-top: 0;
                        border-radius: 10px;

                        a {
                            &:hover {
                                background-color: rgb(238 238 238) !important;
                                color: black;
                            }
                        }
                    }
                }
            }
        }

        .right-side {
            .menu {
                .header-ul {
                    display: flex;
                    align-items: center;

                    .header-li {
                        margin: 0 20px;
                        width: 30px;
                        height: 26px;

                        .header-li-img {
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                        }

                        .header-bi-icon {
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                            font-size: 20.5px!important;
                            color: hsl(216, 41%, calc(35% + 0%)) !important;
                        }

                        .bi{
                            &:before{
                                line-height: inherit!important;
                            }
                        }

                        svg {
                            fill: #404040;
                            width: 20px;
                            height: 20px;
                        }

                        i {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .profile {
                        display: flex;
                        align-items: center;
                        height: auto;
                        width: auto;

                        img.header-li-img {
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                            border: 1px solid #446387;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

/* sub header styles */

.sub-header {
    margin-left: 70px;
    // background-color: $sub-header-bg;
    background-color: $white;
    border-bottom: 1px solid $common-border-color;

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 37px;

        .left-side {
            .sub-header-ul {
                display: flex;
                align-items: center;

                a {
                    font-size: 14px;
                }

                .nav-menu {
                    padding: 0 7px;

                    &.active {
                        a {
                            color: hotpink;
                        }


                        a.dropdown-toggle {
                            color: hotpink;

                            ul {
                                li {
                                    a {
                                        color: black;
                                    }
                                }
                            }
                        }
                    }

                    ul.dropdown-menu {
                        margin-top: 0;
                        border-radius: 10px;
                        border: none;
                        top: 1px !important;

                        .ticket-tab {
                            a {
                                padding: 7px 10px;
                                color: black;
                                font-size: 14px;

                                &:hover {
                                    background-color: rgb(238 238 238) !important;
                                    color: black !important;
                                }
                            }

                            &.active {
                                a {
                                    background-color: rgb(238 238 238) !important;
                                    color: black;
                                }
                            }
                        }

                        .dropright {

                            a {
                                display: block;
                                color: black;
                                font-size: 14px;

                                &::after {
                                    display: none;
                                }
                            }

                            div.dropdown-menu {
                                border-radius: 10px;
                                border: none;
                                margin: 0;
                            }

                            &:hover {
                                div.dropdown-menu {
                                    display: block;
                                }
                            }
                        }
                    }

                    .sub-menu {
                        a {
                            padding: 10px;

                            &:hover {
                                background-color: rgb(238 238 238) !important;
                            }
                        }
                    }
                }

                .ticket-tab {
                    a {
                        padding: 0;
                    }
                }

                .nav-menu {
                    .btn.dropdown-toggle {
                        padding: 0;

                        &::after {
                            vertical-align: middle;
                            margin-left: -5px;
                        }
                    }
                }
            }
        }

        .right-side {
            .sub-header-ul {
                display: flex;
                align-items: center;

                .sub-header-li {
                    display: flex;

                    // padding: 7px 0;
                    i {
                        margin: 0 10px;

                        &::before {
                            line-height: unset;
                            vertical-align: middle;
                        }
                    }

                    svg,
                    .cls-1 {
                        fill: $primary-dark;
                    }

                    .subheader-li-img {
                        margin: 0 20px;
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                    }

                    ul.dropdown-menu {
                        svg {
                            fill: $primary-dark;
                            width: 20px;
                        }
                    }

                    &::after {
                        border-left: 1px solid $secondary-light;
                        content: "";
                    }

                    .dropleft {
                        &:hover {
                            .dropdown-menu {
                                display: block;
                            }
                        }

                        a {
                            padding: 7px 20px;
                            color: black;

                            &::before {
                                display: none;
                            }
                        }

                        div.dropdown-menu {
                            border-radius: 10px;
                            border: none;
                            margin: 0;
                        }
                    }
                }

                .sub-header-li:nth-last-of-type(1) {

                    &::after {
                        display: none;
                    }
                }

                .sub-header-li.show {
                    ul.dropdown-menu {
                        margin-top: 0;
                        border-radius: 10px;
                        border: none;
                        top: 8px !important;
                        left: -20px !important;

                        .second-dropdown {
                            left: -160px !important;                            
                        }

                        a {
                            font-size: 15px;
                            display: flex;
                            align-items: center;

                            &:hover {
                                background-color: rgb(238 238 238) !important;
                                color: black !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* main wrapper content */

.main-wrapper {
    padding-left: 70px !important;
    overflow-x: hidden;
    overflow-y: auto;
    height: 76vh;

    .content {
        // padding: 0;

        .alert-success {
            background-color: #d4edda;
            color: #155724;
            border: 1px solid transparent;

            strong {
                font-weight: 400;
            }
        }

        .alert-danger {
            background-color: #f8d7da;
            color: #721c24;
            border: 1px solid transparent;

            strong {
                font-weight: 400;
            }
        }

        .table-height {
            .table-responsive {
                overflow: unset;
            }
        }

        .dataTables_wrapper {
            overflow-x: auto;
            height: calc(100vh - 200px);

            table {
                tbody {
                    tr {
                        td.description-break {
                            word-break: break-word;
                        }
                    }
                }
            }

            .table-bordered th,
            .table-bordered td {
                border: none;
                border-bottom: 1px solid rgb(222 226 230);
                font-size: 14px;
            }

            .table-bordered {
                border: none;
                // overflow: auto;
                width: 100% !important;

                &.scroll-x-table {
                    // display: block;
                    margin-bottom: 0;
                }
            }

            thead {
                background-color: $white;
                position: sticky;
                top: 0;
                z-index: 1;

                tr {
                    th {
                        font-size: 14px;
                        width: auto !important;
                        white-space: nowrap;

                        .form-group {
                            padding-bottom: 0;
                        }

                        &.sorting {
                            &::after {
                                // display: none;
                                // display: inline-block;
                                font-family: "Font Awesome 5 Free";
                                content: "\f107";
                                margin-right: 5px;
                                font-size: 14px;
                                color: grey;
                                cursor: pointer;
                                margin-left: 0.5rem;
                                vertical-align: bottom;
                                visibility: hidden;
                            }
                        }

                        &.sorting_asc {
                            color: hotpink;

                            &::after {
                                content: "\f106";
                                color: hotpink;
                            }
                        }

                        &.sorting_desc {
                            &::after {
                                content: "\f107";
                            }
                        }
                    }

                    th {
                        &:hover {
                            &::after {
                                // display: inline-block;
                                visibility: inherit;
                            }
                        }
                    }
                }

                tr:nth-of-type(1) {
                    background-color: #F5F5F5;
                    color: $black;
                    box-shadow: inset 0 -1px 0 rgb(222 226 230), inset 0 0 0 rgb(222 226 230);

                    th {

                        padding: 6px 12px;
                        vertical-align: middle;
                        border-bottom: unset;
                    }
                }

                tr:nth-of-type(2) {
                    box-shadow: inset 0 -1px 0 rgb(222 226 230), inset 0 0 0 rgb(222 226 230);

                    th {
                        border-bottom: unset;
                        padding: 6px 12px;
                        vertical-align: middle;

                        .form-control {
                            height: calc(0.9em + 0.95rem + 2px);
                            border-radius: 0;
                            padding: 6px;
                            font-size: 14px;
                        }

                        input {
                            font-size: 14px;
                        }

                        .select2-selection--single {
                            height: calc(0.9em + 0.95rem + 2px);
                            border-radius: 0;
                            padding: 7.5px 6px;

                            .select2-selection__arrow {
                                top: -3px;
                            }

                            .select2-selection__rendered {
                                line-height: 1.6;
                            }
                        }
                    }

                    .search-box-container {
                        .select2-hidden-accessible {
                            position: unset !important;
                        }

                        .form-group {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            tbody {
                background-color: $white;

                tr {
                    &:hover {
                        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
                        z-index: 2;
                        background-color: rgb(250 250 250);
                    }

                    td {
                        vertical-align: middle;

                        a {
                            color: black;
                            font-size: 14px;
                        }


                    }

                    td.text-center {
                        width: 0%;
                    }
                }

                tr {
                    td {

                        .btn-success,
                        .btn-danger {
                            color: white !important;
                        }
                    }

                    td:not(:last-child) {
                        &:hover {

                            .btn-success,
                            .btn-danger {
                                color: white !important;
                            }

                            a {
                                // font-weight: 600;
                                color: #2a8adc !important;
                            }
                        }
                    }
                }


                .btn-group {
                    .btn {
                        .fa-list-ul {
                            font-size: 14px;
                        }

                        &:focus {
                            box-shadow: unset;
                        }
                    }

                    ul.dropdown-menu {
                        margin-top: 0;
                        border-radius: 10px;
                        padding: 0;

                        .dropdown-submenu {
                            .dropdown-menu {
                                display: none;
                            }
                        }

                        li {
                            a {
                                padding: 7px 20px;

                                .bi {
                                    margin-right: 10px;
                                }

                                &:hover {
                                    background-color: #EEEEEE;
                                }
                            }

                            a[data-method="delete"],
                            a.bg-custom-red {
                                &:hover {
                                    background-color: #fbebec;
                                    color: #ff3b3c !important;
                                }
                            }
                        }

                        li:nth-of-type(1) {
                            a {
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                            }
                        }

                        li:nth-last-of-type(1) {
                            a {
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }
                        }

                        &.show {
                            left: -38px !important;
                            width: fit-content;
                            top: -10px !important;
                        }

                        &.show[x-placement="top-start"] {
                            top: 10px !important;
                        }
                    }

                    &:hover {
                        ul.dropdown-menu {
                            // display: block;
                            left: unset;
                            right: 26px;
                            top: 15px;

                            .dropdown-submenu {
                                &:hover {
                                    .dropdown-menu {
                                        top: -35px;
                                        right: 190px;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tfoot {
                position: sticky;
                background-color: #F5F5F5;
                bottom: -1px;
                z-index: 1;

                tr {
                    th {
                        padding: 6px;
                    }
                }
            }

            .bottom {
                position: fixed;
                bottom: 46px;
                width: calc(100% - 110px);
                background-color: #fff;
                // z-index: 1;
                padding: 0 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .dataTables_info {
                    color: $grey-light;
                }

                .dataTables_paginate {

                    .pagination {
                        align-items: center;
                        margin-bottom: 0;
                        padding-top: 0;

                        .page-item {
                            display: none;

                            &.previous,
                            &.next {
                                display: block;

                                a {
                                    &:hover {
                                        color: $white !important;
                                    }
                                }
                            }

                        }
                    }

                    .paginate_button {
                        .page-link {
                            border: none !important;
                            color: $white;
                            width: 35px;
                            overflow: hidden;
                            text-indent: -9999px;


                            &::before {
                                color: $primary-dark;
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                opacity: 0.75;
                                float: left;
                                line-height: 16px;
                                text-indent: 0;
                            }

                            &:hover {
                                background-color: $white;
                            }
                        }

                        &.disabled {
                            .page-link {
                                &::before {
                                    color: $grey-light;
                                }
                            }
                        }

                        &.previous {
                            .page-link {
                                &::before {
                                    content: "\f053";
                                }
                            }
                        }

                        &.next {
                            .page-link {
                                &::before {
                                    content: "\f054";
                                }
                            }
                        }
                    }
                }

                .dataTables_length {
                    label {
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        margin-top: 5px;

                        select {
                            margin: 0 5px;
                            padding-right: 8px;
                            width: 80px;
                            font-size: 12px;
                            border-radius: 0;

                            // option {
                            //    color: rgb(59, 46, 46);
                            // }
                        }
                    }
                }
            }
        }
    }
}

/* footer styles */

footer.main-footer {
    border-top: 1px solid $common-border-color;
    position: absolute;
    background-color: $footer-bg;
    bottom: 0;
    width: 100%;
    margin-left: 0 !important;
    padding: 0 0 0 70px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

    .menu {
        .footer-ul {
            display: flex;
            justify-content: flex-end;

            .footer-li {
                border-left: 1px solid $secondary-light;

                .main-anchor {
                    padding: 1px 0;
                    display: flex;
                    align-items: center;
                }

                &:hover,
                &:active {
                    background-color: $secondary-darker;
                }

                .footer-bi-icon {
                    color: hsl(216, 41%, calc(35% + 0%)) !important;
                }

                &:hover,
                &:active {
                    .footer-bi-icon {
                        color: white!important;
                    }
                }
                .footer-bi-icon {
                    margin: 0 20px!important;
                    width: 20px!important;
                    height: 28px!important;
                    object-fit: contain!important;
                    font-size: 1.2rem!important;
                    text-align: center!important;
                }

                .bi{
                    &:before{
                        line-height: inherit!important;
                    }
                }

                .footer-li-img {
                    margin: 0 20px;
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }
            }
        }
    }
}

/* sidebar purchase styles */

.sidebar-ul {
    .sidebar-li {

        &.show {
            margin-left: 5px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            background-color: $primary-dark;

            svg,
            .cls-1 {
                fill: white !important;
            }

            i {
                color: white!important;
            }
        }

        &:hover {
            margin-left: 5px;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            background-color: $primary-dark;

            svg.sidebar-li-img,
            .cls-1,
            .cls-2 {
                fill: white !important;
            }

            i,
            .bi-font-icon{
                color: white!important;
            }

            .inner-ul {
                display: flex;
                max-width: 560px;
                width: max-content;
                flex-wrap: wrap;
                box-shadow: unset;
            }
        }

        a.dropdown-toggle {
            display: block;
        }

        .inner-ul {
            padding: 10px 0;
            border-radius: 15px;
            border-top-left-radius: 0;
            background-color: $primary-dark;

            &.show {
                display: flex !important;
                flex-wrap: wrap;
                max-width: 560px;
                width: max-content;
            }

            h5 {
                // font-weight: 600;
                color: white;
            }

            .inner-li {
                padding: 5px 0;

                .inner-a {
                    display: block;
                    padding: 0 12px;

                    .letter-sentence {
                        display: flex;

                        &:hover {
                            background-color: white;
                            border-radius: 7px;
                            box-shadow: 0 5px 14px 0 rgb(58 58 58 / 20%);

                            .content-div {
                                .heading {
                                    color: $primary-dark;
                                    font-weight: 600;
                                }

                                .description {
                                    color: grey;
                                }
                            }
                        }

                        .img-div {
                            display: flex;
                            align-items: center;
                            padding: 0 15px;
                            border-radius: 7px;
                            background-color: white;

                            svg,
                            .cls-1,
                            .cls-2 {
                                width: 35px;
                                height: 35px;
                                fill: $primary-dark !important;

                                .cls-1,
                                .cls-2 {
                                    fill: $primary-dark !important;
                                    clip-path: unset;
                                }

                            }

                            img {
                                width: 35px;
                                height: 35px;
                                object-fit: contain;
                            }

                            .bi{
                                width: 35px!important;
                                height: 35px!important;
                                color: hsl(216, 41%, calc(35% + 0%)) !important;
                                font-size: 2rem!important;
                            }


                            i {
                                width: 35px!important;
                                height: 35px!important;
                                object-fit: contain!important;
                                color: hsl(216, 41%, calc(35% + 0%)) !important;
                                font-size: 2rem!important;
                            }
                        }

                        .content-div {
                            padding-block: 5px;

                            .heading {
                                color: white;
                                font-size: 0.8125rem;
                            }

                            .description {
                                width: 180px;
                                color: #6e9fca;
                                white-space: break-spaces;
                                font-size: 0.8125rem;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* header bookmark styles */

header {
    .bookmark {
        cursor: pointer;
    }
}

.bookmark-ul {
    top: 37px;
    border-radius: 15px;
    border-top-left-radius: 0;
    box-shadow: 0 5px 14px 0 rgb(58 58 58 / 20%);
    max-height: 490px;
    overflow-y: auto;
    overflow-x: hidden;

    .bookmark-li {
        border-bottom: 1px solid $common-border-color;
        display: flex;
        align-items: center;

        a#chain {
            i {
                color: $primary-light;
            }
        }

        .inner-a {
            display: block;
            padding: 6px 16px;
            white-space: nowrap;

            .letter-sentence {
                display: flex;
                align-items: center;

                h1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 42px;
                    height: 42px;
                    border-radius: 6px;
                    background: $primary-light;
                    font-size: 1.5rem;
                }

                img {
                    width: 20px;
                    height: 30px;
                    object-fit: contain;
                }

                p {
                    padding-left: 12px;
                    line-height: 16px;
                }
            }
        }

        &:hover {
            background-color: $primary-light;

            .inner-a {
                .letter-sentence {
                    h1 {
                        background-color: $white;
                        color: $primary-light !important;
                    }

                    p {
                        color: $white;
                    }
                }
            }

            #chain {
                i {
                    color: $white
                }
            }
        }
    }

    .bookmark-li:nth-of-type(2) {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #eef1f6;
        min-height: 50px;

        .inner-a {
            .letter-sentence {
                h1 {
                    background-color: $white;
                    color: $primary-light !important;
                }

                p {
                    color: $primary-light;
                    font-family: "Proxima Nova bold";
                }
            }
        }


        &:hover {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background-color: #eef1f6;
        }
    }

    .bookmark-li:nth-last-of-type(1) {
        border-bottom: none;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;

        &:hover {
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 15px;
        }
    }
}

/* header quickadd styles */

.quickadd {
    cursor: pointer;

    .quickadd-div {
        width: 900px;
        padding: 20px 15px;
        border-radius: 15px;
        box-shadow: 0 5px 14px 0 rgb(58 58 58 / 20%);
        top: 42px;
        left: -12rem;

        .multi-column-dropdown {
            padding-left: 0;

            .round-element {
                padding: 4px 10px;
                border-radius: 15px;
                background-color: $primary-light;
                color: $white;
                font-size: 0.8125rem;
                font-family: "Proxima Nova Bold";

                img {
                    margin-right: 7px;
                    width: 15px;
                }

                .quick-add-icon {
                    color: #fff!important;
                    margin-right: 7px;
                    width: 15px;
                    font-size: 17px;
                }
            }

            li {
                margin: 0 20px 10px 10px;
                white-space: nowrap;

                &::before {
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                    content: "\f067";
                    color: $primary-dark;
                    margin-right: 5px;
                    font-size: 12px;
                }
            }
        }
    }
}

/* header pin styles */

header {
    .pin-ul {
        border-top-left-radius: 15px;
        top: 42px;

        .bookmark-li:nth-of-type(1) {
            &:hover {
                border-top-left-radius: 15px;
            }
        }
    }
}


/* sub header multi-select styles */

.multi-select {
    cursor: pointer;

    .multiselect-div {
        padding: 15px 20px;
        border-radius: 15px;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);

        .font-color {
            color: $secondary-dark;
        }

        .multiselect-subdiv {
            font-size: 0.875rem;

            img {
                width: 16px;
                margin-right: 5px;
            }
        }
    }
}

/* header global search styles */

.global-search {

    .select2-container {
        width: 240px !important;
    }

    .select2-selection {
        border-radius: 20px;
        padding-left: 1.4rem;
    }
}

.global-search-dropdown {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;

    .select2-results {
        ul.select2-results__options {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        .select2-results__option {

            padding-left: 1.5rem;
            border-bottom: 1px solid rgb(202, 210, 224, 0.3);
            color: $primary-darker;

            &:hover {
                background-color: $secondary-darker !important;
                color: $white !important;
            }
        }

        .select2-results__option:nth-last-of-type(1) {
            border-bottom: none;
        }
    }
}

.select2-container--default #select2-global-search-select-results .select2-results__option--highlighted[aria-selected] {
    background-color: $secondary-darker !important;
    color: $white !important;
}

/* sub header page tips */

.page-tip {
    cursor: pointer;

    .pagetip-div {
        padding: 11rem 0.5rem 0 0.5rem;
        min-width: 330px;
        min-height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .inner-div {
            overflow-y: auto;
            height: 360px;
            padding: 0 1rem;

            .content {
                border-bottom: 1px solid rgb(202, 210, 224, 0.6);
            }

            .content:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
    }
}

/* sub header view report */

.view-report {
    cursor: pointer;

    .viewreport-div {
        padding: 10px 0;
        border-radius: 15px;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .viewreport-subdiv {
            display: flex;

            img {
                margin: 0 15px;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }

            .inner-div {
                border-right: 1px solid $secondary-light;
                border-left: 1px solid $secondary-light;
            }
        }
    }
}

/* footer whats new */

.whats-new {
    cursor: pointer;

    .whatsnew-div {
        padding: 11rem 0.5rem 0 0.5rem;
        width: 330px;
        height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .whatsnew-ul {
            overflow-y: auto;
            height: 360px;
            padding-left: 20px;

            .whatsnew-li {
                border-bottom: 1px solid rgb(202, 210, 224, 0.6);
                margin: 0 20px;
                margin-bottom: 20px;
                list-style-type: disc;

                .text-three {
                    line-height: 16px;
                }

                .text-four {
                    a {
                        &:hover {
                            color: $unique !important;
                        }
                    }
                }

                &::marker {
                    color: $primary-dark;
                    font-size: 20px;
                }
            }

            .whatsnew-li:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
    }
}

/* footer Announcement */

.announcement {
    cursor: pointer;

    .announcement-div {
        padding: 11rem 0.5rem 0 0.5rem;
        width: 330px;
        height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .announcement-ul {
            overflow-y: auto;
            height: 360px;
            padding-left: 20px;

            .announcement-li {
                border-bottom: 1px solid rgb(202, 210, 224, 0.6);
                margin: 0 20px;
                margin-bottom: 20px;
                list-style-type: disc;

                .text-three {
                    line-height: 16px;
                }

                .text-four {
                    a {
                        &:hover {
                            color: $unique !important;
                        }
                    }
                }

                &::marker {
                    color: $primary-dark;
                    font-size: 20px;
                }
            }

            .announcement-li:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
    }
}


/* footer Internal Announcement */

.internal_announcement {
    cursor: pointer;

    .internal_announcement-div {
        padding: 11rem 0.5rem 0 0.5rem;
        width: 330px;
        height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .internal_announcement-ul {
            overflow-y: auto;
            height: 360px;
            padding-left: 20px;

            .internal_announcement-li {
                border-bottom: 1px solid rgb(202, 210, 224, 0.6);
                margin: 0 20px;
                margin-bottom: 20px;
                list-style-type: disc;

                .text-three {
                    line-height: 16px;
                }

                .text-four {
                    a {
                        &:hover {
                            color: $unique !important;
                        }
                    }
                }

                &::marker {
                    color: $primary-dark;
                    font-size: 20px;
                }
            }

            .internal_announcement-li:nth-last-of-type(1) {
                border-bottom: none;
            }
        }
    }
}

/* footer refer earn */

.refer-earn {
    cursor: pointer;

    .refer-div {
        padding: 15rem 2.5rem 0 2.5rem;
        width: 350px;
        height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .first-div {
            h2 {
                position: absolute;
                top: 2rem;
                right: 2.5rem;
                font-size: 1.125rem;
            }

            p {
                position: absolute;
                top: 5.5rem;
                right: 1rem;
                font-size: 0.875rem;
            }

            a {
                position: absolute;
                top: 11rem;
                right: 3rem;
                font-size: 1rem;

                &:hover {
                    color: $white !important;
                }
            }
        }

        .second-div {
            .content {
                margin-bottom: 10px;

                .question,
                .answer {
                    font-size: 12px;
                    margin-bottom: 0;
                    line-height: 18px;
                }
            }
        }
    }
}

/* footer help */

.help {
    cursor: pointer;

    .help-div {
        padding: 15rem 0rem 1rem 2rem;
        right: -40px;
        width: 330px;
        height: 550px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        h5 {
            font-size: 16px;
        }

        .help-ul {
            list-style-type: disc;
            padding-left: 20px;

            li {
                &::marker {
                    color: $secondary-darker;
                    font-size: 20px;
                }
            }
        }

        .browse-text {
            position: absolute;
            bottom: 15px;

            a {
                font-size: 16px;

                &:hover {
                    color: $unique !important;
                }
            }
        }
    }
}

/* footer actibvity reminder */

.reminder-activity {
    cursor: pointer;

    .reminder-div {
        padding: 1rem 1rem 0 1rem;
        width: 330px;
        height: 425px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);

        .gray-background {
            color: hsl(216,41%,35%)!important;
            text-align: center;
            background-color: #eee;
            border-radius: 9px;
            height: 34px;
            margin-bottom: 10px;

            h4{
                line-height: 1.6;
            }
        }

        .nav-tabs {
            justify-content: center;

            .nav-item {
                i {
                    font-size: 1.5rem;
                    width: 22px;
                    height: 22px;
                    color: #D3D3D3;
                }

                .h-img {
                    display: none;
                }

                .active {
                        i {
                            font-size: 1.5rem;
                            width: 22px;
                            height: 22px;
                            color: hsl(216, 41%, 35%) !important;
                        }
                    background-color: transparent !important;

                    .h-img {
                        display: block;
                    }

                    .a-img {
                        display: none;
                    }
                }
            }

            .nav-item:nth-of-type(2) {
                .nav-link {
                    height: 40px;
                    border-right: 1px solid $secondary-light !important;
                    border-left: 1px solid $secondary-light !important;
                }
            }
        }

        .tab-content {
            .tab-pane {
                overflow-y: auto;
                height: 290px;
                padding-right: 10px;

                .select2 {
                    margin-bottom: 10px;

                    .select2-selection {
                        padding: 3px 10px;
                        height: 30px;

                        .select2-selection__rendered {
                            font-size: 13px;
                        }
                    }
                }

                .inner-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    // border-bottom: 1px solid rgb(202, 210, 224, 0.6);
                    padding: 3px 0;
                    margin-bottom: 0.5rem;

                    &:hover {
                        background-color: #eee;
                        border-radius: 10px;
                    }

                    .snooze,
                    .cancel {
                        width: 20px;
                    }

                    .font-weight-normal {
                        width: 120px;
                    }

                    span {
                        display: block;

                        &.name {
                            color: #35527e;
                            font-size: 13px;
                        }

                        &.issue {
                            color: #6d91c7;
                            font-size: 13px;
                            line-height: 1;
                        }

                        &.type {
                            color: #57a934;
                            font-size: 12px;
                            line-height: 1;
                        }
                    }

                    button {
                        background-color: $unique;
                        border-radius: 20px;
                        padding: 3px 18px;
                    }
                }

                .inner-div:nth-last-of-type(1) {
                    border-bottom: none;
                }
            }

            #pending_approval {
                .inner-tabs {
                    display: flex;
                    justify-content: space-evenly;
                    margin-bottom: 10px;

                    .footer-anchor {
                        &.active {
                            i {
                                color: #35527e!important;
                            }
                        }
                    }

                    i {
                        width: 25px;
                        height: 25px;
                        font-size: 1.2rem;
                        color: lightgray;
                    }
                }

                button {
                    background-color: #33d55d;
                    border-radius: 20px;
                    padding: 3px 18px;
                }
            }


        }
    }
}

/* footer support */

.support {
    cursor: pointer;

    .support-div {
        padding: 1rem 1rem 1rem 1rem;
        width: 330px;
        border-radius: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

        .gray-background {
            color: hsl(216,41%,35%)!important;
            text-align: center;
            background-color: #eee;
            border-radius: 9px;
            height: 34px;
            margin-bottom: 10px;

            h4{
                line-height: 1.6;
            }
        }
    }

    input,
    select,
    textarea,
    .screenshot-div {
        border-color: #D1D5E6 !important;
        color: $secondary-light;
        font-size: 0.875rem;

        &::placeholder {
            color: $secondary-light !important;
        }
    }

    .screenshot-div {
        .screen_shot {
            p {
                color: $secondary-light !important;
                font-size: 13px;
            }
        }

        #support_reset_btn,
        #mobile_support_reset_btn {
            position: absolute;
            top: 3px;
            right: 8px;
        }
    }
}

/* sub header period datepicker */

.period {
    cursor: pointer;

    .dropdown-toggle {
        .subheader-li-img {
            &:focus {
                outline: unset;
            }
        }
    }
}

.period-datepicker {
    border-radius: 15px;
    right: 3rem !important;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
    border: 0;

    .drp-calendar.left,
    .drp-calendar.right {
        padding: 0;
        border: none;

        .calendar-table {
            padding-right: 0;
            border: none;

            thead {
                tr:nth-of-type(1) {
                    background-color: $secondary-darker;

                    th {
                        border-radius: unset;
                        padding: 15px 20px;
                    }

                    .prev {
                        border-top-left-radius: 20px;

                        span {
                            border-color: $white;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .next {
                        border-top-right-radius: 20px;

                        span {
                            border-color: $white;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    .month {
                        select {
                            border-radius: 15px;
                            border: none;
                            background-color: $white;
                        }
                    }
                }

                tr:nth-of-type(2) {
                    color: $primary-darker;
                }
            }

            tbody {
                tr {
                    color: $grey-dark;

                    td {
                        line-height: 30px;
                    }

                    td.active {
                        background-color: $unique;
                    }
                }
            }
        }
    }

    &::after,
    &::before {
        display: none;
    }

    .drp-buttons {
        padding: 15px;

        .btn-save {
            color: $white;
        }
    }
}

/* reports styles */

.main-reports {
    padding: 0 1rem;

    .main-col {
        margin-bottom: 20px;

        .inner-div {
            display: flex;
            border-radius: 15px;
            background-color: $white;
            box-shadow: rgb(58 58 58 / 20%) 0px 5px 14px 0px;
            cursor: pointer;
            align-items: center;

            .left-side {
                padding: 25px 0px;
                display: flex;
                justify-content: center;

                .first-letter {
                    font-size: 2.5rem;
                }

                .img-div {
                    img {
                        width: 45px;
                        height: 45px;
                        object-fit: contain;
                    }
                }
            }

            .right-side {
                border-left: 1px solid $secondary-darker;

                p {
                    line-height: 15px;
                }
            }

            &:hover {
                .left-side {
                    background-color: $secondary-darker;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;

                    .first-letter {
                        color: $white;
                    }
                }
            }
        }

        .market_place {
            display: flex;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background-color: $white;
            box-shadow: rgb(58 58 58 / 20%) 0px 5px 14px 0px;
            cursor: pointer;
            align-items: center;
            height: 120px;

            .left-side {
                padding: 36px 0px;
                display: flex;
                justify-content: center;

                .first-letter {
                    font-size: 2.5rem;
                }

                .img-div {
                    img {
                        width: 45px;
                        height: 45px;
                        object-fit: contain;
                    }
                }
            }

            .right-side {
                border-left: 1px solid $secondary-darker;

                p {
                    line-height: 15px;
                }

                .bottom-side {
                    margin-top: 10px;
                    border-top: 1px solid lightgray;

                    p {
                        margin-top: 10px;
                        line-height: 15px;
                        color: #1361b3 !important;
                    }
                }
            }

            &:hover {
                .left-side {
                    background-color: $secondary-darker;
                    border-top-left-radius: 15px;

                    .first-letter {
                        color: $white;
                    }
                }
            }
        }

        .order_now{
            display: flex;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: #EEF3F9;
            box-shadow: rgb(58 58 58 / 20%) 0px 8px 14px 0px;
            cursor: pointer;
            align-items: center;
            height: 50px;

            h5 {
                text-align: center;
                color: darkblue;
                width: 430px;
                align-items: center;
            }

            &:hover {
                background-color: #b6cbe5;
            }
        }
    }

    .records-info {
        display: flex;
        align-items: center;

        .box-unique {
            width: 10px;
            height: 10px;
            background-color: $unique;
        }

        .box-theme {
            width: 10px;
            height: 10px;
            background-color: $primary-dark;
        }
    }

    &.crm-sales-main-reports,
    &.general-master-main-reports {
        .main-col {
            .right-side {
                .fa-sign-out-alt {
                    color: $primary-darker;
                    position: absolute;
                    right: 10;
                    bottom: -30;
                }
            }
        }
    }

    &.service-main-reports {
        .main-col {
            .right-side {
                .fa-sign-out-alt {
                    color: $primary-darker;
                    position: absolute;
                    right: 10;
                    bottom: -20;
                }
            }
        }
    }
}


/* form page */
.page-form {

    .custom-col {
        .form-group {
            height: 100%;
            border: 1px solid $secondary-lighter;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;

            #file-image {
                width: 100px;
            }

            .file-upload-form {
                cursor: pointer;

                #start.hidden {
                    display: none;
                }

                #start {
                    img {
                        width: 30px;
                    }
                }

                #file-image.hidden {
                    display: none;
                }

                input[type="file"] {
                    display: none;
                }
            }

            label {
                cursor: pointer;
                width: 130px;
            }
        }
    }

    hr {
        width: 100%;
    }

    .form-group {
        margin-bottom: 2px;

        input,
        select {
            &.sol_required {
                border-left: 2px solid $required-red;
            }
        }

        .note-editing-area.sol_required {
            border-left: 2px solid $required-red;
        }

        select.sol_required+.select2-container {
            .select2-selection {
                border-left: 2px solid $required-red;
            }
        }

        label {
            color: $grey-light;
            font-weight: 400 !important;
            margin-bottom: 0;
        }

        input,
        textarea {
            border: 1px solid $secondary-lighter;
            border-radius: 0;
            color: $primary-darker;
        }

        .company-adderss-box {
            border: 1px solid $secondary-lighter;
            border-radius: 0;
            color: $primary-darker;
            height: 37px;
            overflow-y: scroll;
        }

        select {
            border-radius: 0;
            color: $primary-darker;

            ::placeholder {
                color: $primary-darker;
            }

            option {
                color: $black;
            }
        }

        .input-group {
            flex-wrap: unset;

            .input-group-addon {
                .btn {
                    border-left: 0;
                    border-radius: 0;
                }
            }
        }
    }

    .select2 {
        width: 100% !important;

        .select2-selection {
            border: 1px solid $secondary-lighter;
            border-radius: 0 !important;

            .select2-selection__rendered {
                color: $primary-darker;
            }
        }
    }
}

/* styles want like dashboard page */

.replicate-dash {
    height: auto;
    padding: 0;

    .main-div {
        height: auto;

        .modules-icons {
            padding: 0;
            min-height: unset;
            box-shadow: unset;

            .row {
                justify-content: space-evenly;
            }

            .w-100 {
                margin: 1rem 0;
            }

            .rounded-box {
                margin-bottom: 0;
            }
        }
    }
}

/* styles for general short forms */

.general-form {
    border: none;
    border-radius: 15px;

    .alert-success {
        background-color: #d4edda;
        color: #155724;
        border: 1px solid transparent;
        // width: 100%;
        margin: 0 1.5rem;

        strong {
            font-weight: 400;
        }
    }

    .alert-danger {
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid transparent;
        // width: 100%;
        margin: 0 1.5rem;

        strong {
            font-weight: 400;
        }
    }

    .box-border {
        .box-header {
            h2 {
                color: $primary-dark;
            }
        }

        .box-body {
            .note-editing-area {
                border-left: 2px solid red;
            }

            input,
            select,
            textarea {
                border-radius: unset;

                &.sol_required {
                    border-left: 2px solid red;
                }
            }

            select.sol_required+.select2-container {
                .select2-selection {
                    border-left: 2px solid $required-red;
                    border-radius: unset;
                }
            }
        }
    }

    // .save,
    // .btn-warning.cancel {
    //     border-radius: 5px !important;
    // }
}

/* styles for tab page header */

.sub-header {
    .sub-header-ul {
        .nav {
            display: flex;
            flex-direction: row;
        }

        .breadcrumb-item {
            img {
                width: 20px;
                height: 20px;
            }

            a {
                color: $primary-darker;

                &:hover {
                    font-weight: 600;
                }
            }
        }

        .breadcrumb-item {
            &.active {
                color: $primary-light;
            }
        }

        .breadcrumb-item+.breadcrumb-item::before {
            content: "\f054";
            color: $primary-light;
            font-family: "Font Awesome 5 Free";
            float: none;
        }
    }
}

.nav-tabs-custom {
    box-shadow: none !important;
    margin-bottom: 0.5rem !important;

    .nav-tabs {
        li {
            border: none !important;

            a {
                background-color: $primary-dark !important;
                font-family: unset !important;

                &:hover,
                &:focus {
                    color: $white !important;
                    background-color: $primary-dark !important;
                }
            }
        }

        li.ticket-tab {
            a {
                background-color: $primary-dark;
            }
        }

        li.active,
        li.ticket-tab.active {
            a {
                background-color: $secondary-light !important;
                color: $white !important;
            }
        }
    }

    .tab-content {
        margin-top: 0.5rem;
        border: 1px solid #d2d6de;
    }
}

.responsive-wireframe {
    display: none;
}

.people-list-page {
    .people {
        li {
            width: auto;

            div.right {
                padding-left: 0;

                p {
                    margin-left: 0;
                }
            }
        }
    }
}

.nav-tabs-custom {
    ul.nav-tabs.nav-wizard {
        border-bottom: 1px solid rgb(202 210 224);
        display: flex;
        align-items: center;

        li {
            margin: 0;

            a {
                background-color: white !important;
                border: none;
                color: black;
                font-weight: 400;
                padding: 5px 0;
                margin: 0 15px;
                border-radius: unset;
                text-transform: capitalize;
                font-size: 14px;

                &:hover,
                &:focus {
                    color: black !important;
                }

                &::after,
                &::before {
                    display: none;
                }
            }
        }

        li.active {
            a {
                color: $primary-dark !important;
                background-color: white !important;
                border: none;
                font-weight: 400;
                border-bottom: 2px solid $primary-dark;
            }
        }
    }
}


.ticket-inner-page {
    .content {
        height: 100%;
        overflow-y: hidden;
    }
}

.subheader-ticket-inner-left {
    margin-top: 0 !important;

    .ticket-time-header {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .prority {
        display: flex;

        .quick {
            span {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 10px;

                &::after {
                    content: '';
                    border-left: 1px solid $primary-dark;
                }
            }
        }

        .with-right-border {
            display: flex;

            a {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 10px;

                &::after {
                    content: '';
                    border-left: 1px solid $primary-dark;
                }
            }
        }

        svg {
            margin: 0 10px;
            width: 20px;
            height: 20px;
            fill: $primary-dark;
            object-fit: contain;
        }

        .custom_left-border{
            border-left: 1px solid blue;
            height: 25px;
            margin-top: 3px;
            margin-left: 10px;
        }

        .ticket_left_icon {
            font-size: 1.3rem;
            margin-left: 14px;
            color: hsl(216, 41%, calc(35% + 0%)) !important;
        }
    }
}

.subheader-ticket-inner-right {

    .right-side {
        .sub-header-ul {
            img {
                height: 30px;
                width: 30px;
                border-radius: 50%;
            }

            svg {
                width: 20px;
                height: 20px;
                fill: $primary-dark;
            }
        }
    }
}

.ticket-inner-content {
    .ticket-inner-left {
        border-right: 1px solid #cad2e0;
        overflow-y: auto;

        .one {
            display: flex;
            align-items: center;
            padding: 1rem 0 0 1rem;

            .img-div {
                padding-right: 1rem;

                img {
                    border-radius: 10px;
                }
            }

            .ticket-number {
                font-size: 0.875rem;
                color: $primary-light;
            }

            .ticket-company-address {
                color: $primary-light;

                .text-uppercase {
                    font-size: 14px;
                }

                a {
                    color: $primary-light;
                }
            }
        }

        .ticket-order {
            text-align: center;
            margin-bottom: 10px;
        }

        .accordion {
            .card {
                box-shadow: unset;
                border-radius: unset;

                .card-header {
                    border-top: 1px solid #00000020;
                    border-bottom: none;

                    h2 {
                        button {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            i {
                                text-align: right;
                            }
                        }
                    }

                    .btn-block {
                        .fa-angle-up {
                            display: block;
                        }

                        .fa-angle-down {
                            display: none;
                        }
                    }

                    .collapsed {
                        .fa-angle-down {
                            display: block;
                        }

                        .fa-angle-up {
                            display: none;
                        }
                    }

                    button {
                        background-color: #f7f8fb;
                        color: $primary-dark;
                    }
                }

                .card-body {
                    padding: 10px;

                    table {
                        margin: 0;

                        thead {
                            tr {

                                th,
                                td {
                                    padding: 0 0 0 10px;
                                    border: none;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }

            .card:nth-last-of-type(1) {
                border-bottom: 1px solid #00000020;
            }
        }
    }

    .ticket-inner-right {
        .tab-box {
            .nav-tabs {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                border: none;
                border-bottom: 1px solid #cad2e0;

                .ticket-tab {
                    margin: 0;

                    a {
                        background-color: white !important;
                        color: $secondary-light;
                        font-weight: 400;
                        padding: 3px 0;

                        &:hover,
                        &:focus {
                            background-color: unset !important;
                            color: $secondary-light !important;
                        }

                        &.active {
                            background-color: unset !important;
                            color: $primary-dark !important;
                            font-weight: 600;
                            border-bottom: 2px solid $primary-dark !important;
                        }
                    }
                }

                .ticket-tab.active a {
                    color: $secondary-light !important;
                }

                .ticket-inner-three-dot-menu {
                    a {
                        &:active {
                            background-color: unset !important;
                        }
                    }

                    svg,
                    .cls-1 {
                        width: 16px;
                        margin-right: 10px;
                        fill: $primary-dark;
                    }
                }

                .more-icon {
                    &::after {
                        display: none;
                    }
                }

                .more-icon-dropdown-menu {
                    border-radius: 10px;

                    .dropdown-item {
                        padding: 5px 10px;
                    }
                }
            }

            .tab-content {
                border: none;
                margin: 10px;
                overflow-y: auto;
                overflow-x: hidden;

                .table-box {
                    .table-responsive {
                        overflow-x: auto;
                    }
                }

                .page-form {
                    .ajax-upload-dragdrop {
                        width: auto !important;
                    }

                    #radioBtn {
                        .btn {
                            border-color: $primary-dark;
                            color: $primary-dark;

                            &.active {
                                background-color: $primary-dark;
                                color: white;
                            }
                        }
                    }

                    .box-footer {
                        border-top: 1px solid #C8C8C8;
                        width: 68%;
                        bottom: 45px;
                        right: 3rem;
                        display: flex;
                        justify-content: flex-end;
                        z-index: 11;
                    }
                }

                .comment-owner-row {
                    border: 1px solid #cad2e0;
                    border-radius: 10px;
                    padding: 10px;
                    margin: 0;

                    img {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}


.chat-li {
    position: relative;

    *::-webkit-scrollbar {
        width: 10px;
    }

    #tabs {
        li {
            a {
                color: $grey-light;
            }

            &.active {
                a {
                    color: $primary-dark;
                    font-weight: 600;
                }
            }

            i{
                -webkit-text-stroke: 1px;
            }
        }

        #group,
        #individual {
            #search {
                height: 30px;
                width: 93%;
                position: absolute;
                bottom: 10px;
                left: 12px;
                background: #f3f3f3;
                padding: 3px 10px;
                border-radius: 15px;

                .fa-search {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    color: #35527e;
                    padding: 6px 10px;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }

                .div-svg {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    padding: 2px 8px;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    background-color: #fff;
                    cursor: pointer;

                    svg {
                        width: 15px;
                        fill: #35527e;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .maximized-chat {
        padding: 1rem 1rem 0 1rem;
        position: absolute;
        top: auto;
        bottom: 100%;
        width: 330px;
        height: 490px;
        z-index: 1000;
        border-radius: 15px;
        right: -110px;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);
        background-color: #fff;

        .minimized-chat-window {
            position: absolute;
            top: 1px;
            right: 15px;
            color: #35527e;
            font-size: 16px;
        }

        .gray-background {
            color: hsl(216,41%,35%)!important;
            text-align: center;
            background-color: #eee;
            border-radius: 9px;
            height: 34px;
            margin-bottom: 10px;

            h4{
                line-height: 1.6;
            }
        }

        #tabs {
            .nav-tabs {
                border: none;
                display: flex;
                padding-left: 6px;

                li {
                    a {
                        border: none;
                    }
                }

                .group_tab {
                    margin-left: 4rem;
                }
            }
        }

        .tab-pane {
            margin: 0 10px;
        }

        #individual-friends {
            overflow: auto;
            height: 340px;

            .friend {
                position: relative;
                display: flex;
                align-items: center;

                .chatProfileImage {
                    font-size: 22px;
                    color: #fff;
                    margin: 10px;
                    width: 42px;
                    height: 42px;
                    border-radius: 100%;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    text-transform: uppercase;
                    display: flex;
                }

                .status {
                    top: 10px;
                    left: 40px;
                }

                p {
                    margin: 0;
                    display: flex;
                    flex-direction: column;

                    strong {
                        font-weight: 600;
                        font-size: 14px;
                        color: #35527e;
                        width: 100%;
                        display: block;
                        line-height: 14px;
                    }

                    .emailid {
                        font-size: 12px;
                    }

                    span:nth-of-type(2) {
                        font-size: 12px;
                    }
                }
            }
        }

        #group-friends {
            overflow: auto;
            height: 340px;

            .group-friend {
                display: flex;
                align-items: center;
                position: relative;

                .chatProfileImage {
                    font-size: 22px;
                    margin: 10px;
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }

                .unread-badge {
                    position: absolute;
                    left: 38px;
                    width: 15px;
                    top: 6px;
                    padding: 0;
                }

                p {
                    margin: 0;

                    strong {
                        color: #35527e;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    #chatview {
        position: absolute;
        display: none;
        top: auto;
        bottom: 100%;
        width: 330px;
        height: 350px;
        z-index: 1000;
        border-radius: 15px;
        right: 14.5rem;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: white;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);

        .profile {
            #chat-from-img {
                display: none !important;
            }
        }

        #chat-messages {
            margin: 10px 10px 0 10px;
            height: 17em;
            overflow-y: auto;

            .message {

                .chatProfileImage {
                    margin: 5px 0 0 5px;
                    color: #fff;
                    font-size: 10px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    float: left;
                    margin: 5px;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                }

                &.right {
                    img {
                        float: right;
                        margin: 5px;
                        border-radius: 100%;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }

        #sendmessage {
            border-top: 1px solid #e7ebee;
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 93%;
            background-color: #f3f3f3;
            margin-left: 10px;
            margin-right: 10px;
            height: 30px;
            border-radius: 20px;

            #text-message {
                border: none;
                resize: none;
                width: 100%;
                background: #f3f3f3;
                padding-left: 1rem;
                padding-top: 2px;
            }

            #send {
                height: 26px;
                top: 1px;
                width: 35px;
                right: 2px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                position: absolute;
                border: none;
                background-color: transparent;

                .fa-paper-plane {
                    color: #35527e;
                }
            }
        }

        .profile {
            display: flex;
            align-items: center;
            background-color: #6d91c7;

            p {
                width: 80%;
                margin: 0;
                padding: 10px;

                .chat-from-name {
                    color: white;
                    font-weight: 600;
                    font-size: 14px;
                }

                .chat-from-emailid {
                    color: white;
                    font-size: 12px;
                }
            }

            #close {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                color: white;
            }
        }
    }

    #create-group-chat {
        position: absolute;
        display: none;
        top: auto;
        bottom: 100%;
        width: 330px;
        height: 600px;
        z-index: 1000;
        border-radius: 15px;
        right: 14.5rem;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);
        background-color: white;

        .topmenu {
            position: relative;

            .group-name {
                background-color: #f8f9fc;
                width: 100%;
                margin-top: 30px;
                margin-bottom: 20px;
                border: none;
                padding: 5px 20px;

                &:focus-visible {
                    outline: none;
                }
            }

            .close-group-chat {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        #friends {
            height: 460px;
            overflow-y: auto;
            margin: 0 10px;

            .friend {
                display: flex;
                align-items: center;

                .custom-checkbox {
                    margin: 0;
                }

                p {
                    margin: 0;
                }

                img {
                    width: 42px;
                    border-radius: 100%;
                    margin: 10px;
                }

                .chatProfileImage {
                    border-radius: 100%;
                    margin: 10px;
                    width: 42px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
        }

        .search {
            .searchinput {
                position: absolute;
                left: 10px;
                bottom: 10px;
                width: 93%;
                height: 30px;
                padding-left: 0;
                border-radius: 15px;
            }
        }
    }

    #group-chatview {
        display: none;
        position: absolute;
        width: 330px;
        height: 350px;
        top: auto;
        bottom: 100%;
        z-index: 1000;
        border-radius: 15px;
        right: 14.5rem;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: #fff;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);

        .profile {
            display: flex;
            align-items: center;
            background-color: #6d91c7;
            position: relative;

            #group-chat-from-letter,
            #group-chat-from-img {
                display: none !important;
            }

            p {
                width: 80%;
                margin: 0;
                padding: 10px;

                .group-chat-from-name {
                    color: white;
                }

                .group-chat-from-emailid {
                    color: white;
                }
            }

            #edit {
                position: absolute;
                top: 10px;
                right: 30px;
                cursor: pointer;
                color: white;
            }

            #group-close {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                color: #fff;
            }
        }

        #group-chat-messages {
            margin: 10px 10px 0 10px;
            height: 17em;
            overflow-y: auto;
            overflow-x: hidden;

            .message {
                .chatProfileImage {
                    margin: 5px 0 0 5px;
                    color: #fff;
                    font-size: 10px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    float: left;
                    margin-left: 5px;
                    margin-top: 5px;
                    margin-right: 0;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                }

                &.right {
                    img {
                        float: right;
                    }
                }
            }
        }

        #group-sendmessage {
            border-top: 1px solid #e7ebee;
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 93%;
            background-color: #f3f3f3;
            margin-left: 10px;
            margin-right: 10px;
            height: 30px;
            border-radius: 20px;

            #group-text-message {
                border: none;
                resize: none;
                width: 100%;
                background: #f3f3f3;
                padding-left: 1rem;
                padding-top: 2px;
            }

            #group-send {
                height: 26px;
                top: 1px;
                width: 35px;
                right: 2px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                position: absolute;
                border: none;
                background-color: #fff;
            }
        }
    }

    #update-group-chat {
        position: absolute;
        display: none;
        top: auto;
        bottom: 100%;
        width: 330px;
        height: 600px;
        z-index: 1000;
        border-radius: 15px;
        right: 14.5rem;
        background-size: 100%;
        background-repeat: no-repeat;
        box-shadow: 0 1px 4px 1px rgb(0 0 0 / 20%);
        background-color: white;

        .update-group-profile {
            position: relative;

            .update-group-name {
                background-color: #f8f9fc;
                width: 100%;
                margin-top: 30px;
                margin-bottom: 20px;
                border: none;
                padding: 5px 20px;

                &:focus-visible {
                    outline: none;
                }
            }

            #update-group-back {
                position: absolute;
                top: 5px;
                right: 30px;
                cursor: pointer;
            }

            #update-group-close {
                position: absolute;
                top: 5px;
                right: 10px;
                cursor: pointer;
            }
        }

        #friends {
            height: 460px;
            overflow-y: auto;
            margin: 0 10px;

            .friend {
                display: flex;
                align-items: center;

                .custom-checkbox {
                    margin: 0;
                }

                p {
                    margin: 0;
                    display: flex;
                    flex-direction: column;

                    strong {
                        color: #35527e;
                    }
                }

                img {
                    width: 42px;
                    border-radius: 100%;
                    margin: 10px;
                }

                .chatProfileImage {
                    border-radius: 100%;
                    margin: 10px;
                    width: 42px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                }
            }
        }

        .search {
            .searchinput {
                position: absolute;
                left: 10px;
                bottom: 10px;
                width: 93%;
                height: 30px;
                padding-left: 0;
                border-radius: 15px;
            }
        }
    }
}

.error-page-414,
.error-page-404,
.error-page-500,
.error-page-302,
.error-page-403,
.error-page-504 {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 76vh;
    gap: 65px;

    .url-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 240px;

        h2 {
            color: #35527e;
            font-size: 35px;
            font-weight: 600;
        }

        h3 {
            font-size: 20px;
            color: #929292;
        }

        h3.font-weight-bold {
            color: #424141;
        }

        p {
            font-size: 12px;
            color: #929292;
            line-height: 15px;
        }
    }

    .url-img {
        img {
            width: 380px;
        }
    }
}

.error-page-302,
.error-page-504 {
    .url-img {
        img {
            width: 300px;
        }
    }
}

.contract-dashboard {
    .header-box-row {
        flex: 0 0 100%;

        .header-box {
            padding: 1px;
            width: 100% !important;

            .bg-new {
                background-color: #39CCCC;
            }

            .blue-box {
                background-color: #009abf;
            }

            .bg-gray-new {
                background: rgba(0, 0, 0, .2);
            }

            .info-box {
                border-radius: 0;
                min-height: 110px;
                margin-bottom: 15px;

                .info-box-icon {
                    height: 110px;
                    width: 30% !important;
                    border-radius: 0;
                    float: left;

                    img {
                        height: 55px;
                        margin: 0;
                    }
                }

                .header-box-label {
                    border-bottom: .5px solid rgba(255, 255, 255, .3);
                    padding: 0 !important;
                    float: none;
                    width: auto !important;
                    display: block !important;
                    height: 55px;
                    background-color: #99bbd5 !important;

                    a {
                        color: #fff;

                        &:hover {
                            color: #fff !important;
                        }
                    }

                    .info-box-number {
                        font-size: 23px;
                        font-weight: 400 !important;
                    }

                    .table {
                        color: white;

                        thead {
                            td {
                                border-top: 0;
                            }
                        }
                    }
                }
            }

            .box-statistics {
                float: left;
            }
        }
    }

    .main-box-row {
        .box {
            border-left: 1px solid #d2d6de;
            border-right: 1px solid #d2d6de;
            border-top: 3px solid #d2d6de !important;
            height: 290px;
            min-height: 290px;

            .bg-lightgray {
                background-color: #c7dadb;
            }

            .round_info {
                position: unset;
            }

            .pull-right {
                float: right;
            }

            .box-body {
                .chart {
                    width: 100%;
                }
            }
        }
    }
}

.hr-dashboard-row,
.inventory-dashboard-row,
.sales-dashboard-row {
    .header-box-row {
        .header-box {
            .info-box {
                display: block;

                .info-box-icon {
                    height: 110px;
                    max-width: 120px;
                    width: 30% !important;
                    float: left;
                }

                .arrow-image {
                    position: absolute;
                    margin-top: 41px;
                    margin-left: -1px;
                    width: 15px;
                }

                .header-box-label {
                    border-bottom: 0.5px solid rgba(255, 255, 255, .3);
                    margin-left: 0;

                    .info-box-number {
                        height: 50px;
                    }
                }

                .perfomance-box-amount {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
}

.round_info_chart {
    .round_info {
        top: 20px;
    }
}

.product-info-boxes {
    .info-box {
        .info-box-icon {
            img {
                max-height: 45px;
            }
        }

        .arrow-image {
            margin-left: -5px;
            position: absolute;
            margin-top: 41px;
        }

        .perfomance-box-amount {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.inventory-dashboard-row {
    .info-box {
        .info-box-icon {
            img {
                max-height: 45px;
            }
        }

        .box-amount-text1 {
            padding-left: 11% !important;
            padding-top: 5%;
        }
    }
}

.imports-overall {
    padding: 0 1rem;

    .main-col {
        margin-bottom: 20px;

        .inner-div {
            display: flex;
            border-radius: 15px;
            background-color: $white;
            box-shadow: rgb(58 58 58 / 20%) 0px 5px 14px 0px;
            cursor: pointer;
            align-items: center;

            .left-side {
                padding: 25px 0px;
                display: block;

                .img-div {
                    display: flex;
                    justify-content: center;

                    svg {
                        width: 40px;
                        height: 40px;
                        fill: $secondary-darker;
                    }
                }
            }

            .right-side {
                border-left: 1px solid $secondary-darker;

                h5 {
                    a {
                        color: $primary-darker;
                    }
                }

                p {
                    line-height: 15px;
                }
            }

            &:hover {

                .left-side {
                    background-color: $secondary-darker;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;

                    .img-div {
                        svg {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}

.main-div {
    .right-side {
        .header-ul {
            li#screen_loading {
                width: 400px;
                display: none;

                .screen-loader {
                    background: $primary-dark;
                    height: 80px;
                    z-index: 1;
                }

                .progress {
                    position: relative;
                    height: 0.6rem !important;
                    width: 300px;
                    margin: 0 auto;
                    border-radius: 10px;

                    .progress-bar {
                        background-color: $secondary-light;
                        position: absolute;
                        // width: 0px;
                        height: 10px;
                        border-radius: 10px;
                        animation: load 4s infinite linear;
                    }
                }

                @keyframes load {
                    0% {
                        width: 0%;
                    }

                    25% {
                        width: 50%;
                    }

                    50% {
                        width: 75%;
                    }

                    75% {
                        width: 85%;
                    }

                    100% {
                        width: 100%;
                    }
                }
            }
        }
    }
}
