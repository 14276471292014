// hsl css variable darken
@function hsl_darken($color_num, $percentage) {
    $color_h: var(--#{$color_num}-h);
    $color_l: var(--#{$color_num}-l);
    $color_s: var(--#{$color_num}-s);
    @return hsl($color_h, $color_s, calc(#{$color_l} - #{$percentage}#{"%"}));
}

// hsl css variable lighten
@function hsl_lighten($color_num, $percentage) {
    $color_h: var(--#{$color_num}-h);
    $color_l: var(--#{$color_num}-l);
    $color_s: var(--#{$color_num}-s);
    @return hsl($color_h, $color_s, calc(#{$color_l} + #{$percentage}#{"%"}));
}

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Proxima Nova Regular',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// other colors
$common-border-color:#cad2e0;

// theme colors
// $primary-darker:#033d6f;
// $primary-dark: #35527e;
// $primary-light:#5575a7;
// $primary-lighter: #6e9fca;
// $secondary-darker:#6d91c7;
// $secondary-dark:#4e6b97;
// $secondary-light:#9aa7bb;
// $secondary-lighter:#d4dff0;
// $grey-dark:#595959;
// $grey-light:#8e8e8e;
// $unique:#ee5f20;
// $color-scroll:#d3deee;
// $sub-header-bg:#eef3fa;
// $footer-bg:#f1f5fc;
$required-red:red;
$required-green: #55e755;

$primary-darker:hsl_lighten(primary-darker, 0);
$primary-dark: hsl_lighten(primary-dark, 0);
$primary-light:hsl_lighten(primary-light, 0);
$primary-lighter: hsl_lighten(primary-lighter, 0);
$secondary-darker:hsl_lighten(secondary-darker, 0);
$secondary-dark:hsl_lighten(secondary-dark, 0);
$secondary-light:hsl_lighten(secondary-light, 0);
$secondary-lighter:hsl_lighten(secondary-lighter, 0);
$grey-dark:hsl_lighten(grey-dark, 0);
$grey-light:hsl_lighten(grey-light, 0);
$unique:hsl_lighten(unique, 0);
$color-scroll:hsl_lighten(color-scroll, 0);
$sub-header-bg:hsl_lighten(sub-header-bg, 0);
$footer-bg:hsl_lighten(footer-bg, 0);
