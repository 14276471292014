@media screen and (min-width: 768px) and (max-width: 1366px) {
    .box .box-footer {
        width: 90%;
    }
}

@media screen and (min-width: 1900px) {
    .box .box-footer {
        width: 93%;
    }
}
